import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'store';
import { BrowserRouter } from 'react-router-dom';
import 'styles/index.scss';
import 'App.css';
import 'index.css';
import 'fonts/line-awesome-1.3.0/css/line-awesome.css';
import 'rc-slider/assets/index.css';

import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { FirebaseAuthProvider } from 'FirebaseAuthProvider';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <FirebaseAuthProvider>
        <ReduxProvider store={store}>
            <BrowserRouter basename="/">
                <App />
            </BrowserRouter>
        </ReduxProvider>
    </FirebaseAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
